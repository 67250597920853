import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useCallback, useMemo } from 'react';

import {
  updateItem,
  removeItem,
  addItem,
  duplicateItem,
  clearItems,
  setItems,
} from './reducer';
import { json2csv } from 'json-2-csv';
import { exportCSV, importCSV } from 'utils/csv';

const requiredItemsFields = ['height', 'width', 'qty'];

export function useItems() {
  return useAppSelector(state => state.items);
}

export function useParsedItems() {
  const items = useItems();

  return useMemo(() => {
    const filtered = items.filter(item => {
      return requiredItemsFields.every(field => item[field]);
    });

    const mapped = filtered.map(
      ({ width, height, qty, rotation, name }, i) => ({
        n: name || `Item ${i}`,
        w: Number(width),
        h: Number(height),
        q: Number(qty),
        r: Number(rotation),
        id: i,
      }),
    );

    return mapped;
  }, [items]);
}

export function useUpdateItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    (index, key, value) => {
      dispatch(updateItem({ index, key, value }));
    },
    [dispatch],
  );
}

export function useRemoveItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    index => {
      dispatch(removeItem({ index }));
    },
    [dispatch],
  );
}

export function useAddItem() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(addItem());
  }, [dispatch]);
}

export function useDuplicateItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    (index, item) => {
      dispatch(duplicateItem({ index, item }));
    },
    [dispatch],
  );
}

export function useClearItems() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(clearItems());
  }, [dispatch]);
}

export function useSetItems() {
  const dispatch = useAppDispatch();

  return useCallback(
    bins => {
      dispatch(setItems(bins));
    },
    [dispatch],
  );
}

export function useExportItemsCSV() {
  const items = useItems();

  return useCallback(
    async filename => {
      const data = await json2csv(items);

      exportCSV(data, `${filename}.csv`);
    },
    [items],
  );
}

export function useImportItemsCSV() {
  const setItems = useSetItems();

  return useCallback(
    event => {
      importCSV({
        onSuccess: res => {
          setItems(res);
        },
        event,
      });
    },
    [setItems],
  );
}
