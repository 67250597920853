import { Wrapper } from './styled';
import { useCalculate } from 'store/output/hooks';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const Calculate = () => {
  const calcualte = useCalculate();

  return (
    <Wrapper
      onClick={() => calcualte()}
      variant="contained"
      color="primary"
      size="small"
    >
      <PlayArrowIcon />
      <span>Calculate</span>
    </Wrapper>
  );
};
