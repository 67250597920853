import { csv2json } from 'json-2-csv';
import { toast } from 'react-toastify';

enum Messages {
  CONSOLE_LOG = '[useImportCSV]::error importing CSV',
  DEFAULT_ERROR = 'Error importing CSV file',
}

/**
 * Exports the CSV file
 *
 * @param data data to be saved as CSV file
 * @param filename name of the exported CSV file
 *
 * @returns void
 */

export function exportCSV(data: BlobPart, filename: string): void {
  const blob = new Blob([data], { type: 'text/csv' });
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = filename;

  document.body.appendChild(link); // Append the link to the document body
  link.click(); // Simulate a click event on the link
  document.body.removeChild(link); // Remove the link from the document body

  URL.revokeObjectURL(downloadUrl);
}

/**
 * Returns a current date to be used for saving files
 *
 * @returns String
 */

export function getCSVDate(): string {
  return new Date().toLocaleDateString('en-US').replaceAll('/', '_');
}

/**
 * Imports the CSV file
 * Reads the file from the file input and parses the CSV (string) into JSON
 *
 * @param event just a file input event
 * @param onSuccess success callback
 * @param onError error callback (optional)
 *
 * @returns void
 */

type ImportCSVProps = {
  event: any;
  onSuccess: (res: any) => void;
  onError?: (error: any) => void;
};

export function importCSV(props: ImportCSVProps): void | undefined {
  const { event, onSuccess, onError } = props;

  const reader = new FileReader();
  const file = event.target.files[0];

  reader.onload = e => {
    const content = e.target?.result;

    if (!content) {
      return;
    }

    const defaultErrorHandler = error => {
      console.log(Messages.CONSOLE_LOG, error);
      toast.error(Messages.DEFAULT_ERROR);
    };

    csv2json(String(content))
      .then(onSuccess)
      .catch(onError || defaultErrorHandler);
  };

  reader.readAsText(file);
}
