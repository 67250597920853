import { User, UserCredential } from 'firebase/auth';

export interface SocialSignInMethods {
  signInWithGoogle: () => Promise<UserCredential>;
  signInWithFacebook: () => Promise<UserCredential>;
  signInWithGithub: () => Promise<UserCredential>;
}

export enum AuthOptions {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  GITHUB = 'GITHUB',
}

export interface AuthContextProps {
  currentUser: User | null;
  signInSocial: (provider: AuthOptions) => Promise<UserCredential>;
  signOut: () => Promise<void>;
}
