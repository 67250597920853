import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';

export interface Bin {
  name?: string;
  width?: string;
  height?: string;
  qty?: string;
}

const createNewBin = ({ name = '', width = '', height = '' } = {}): Bin => ({
  name,
  width,
  height,
});

const createInitialState = (n = 5) =>
  Array.from(Array(n), () => createNewBin());

const initialState: Bin[] = createInitialState();

const binsSlice = createSlice({
  name: 'bins',
  initialState,
  reducers: {
    updateItem(state, { payload: { index, key, value } }) {
      state[index][key] = value;

      // If its last row, add new row
      if (index === current(state).length - 1) {
        state.push(createNewBin());
      }
    },
    removeItem(state, { payload: { index } }) {
      state.splice(index, 1);
    },
    addItem(state) {
      state.push(createNewBin());
    },
    duplicateItem(state, { payload: { item, index } }) {
      state.splice(index, 0, item);
    },
    clearItems() {
      return createInitialState();
    },
    setItems(_, { payload }) {
      return payload;
    },
  },
});

export const {
  updateItem,
  removeItem,
  addItem,
  duplicateItem,
  clearItems,
  setItems,
} = binsSlice.actions;
export default binsSlice.reducer;
