/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import { DEFAULT_APP_TITLE } from 'constants/application';
import { NavBar } from 'app/components/NavBar';
import { Routes } from './routes';
import { ToastContainer } from 'react-toastify';

// Updaters
import AuthUpdater from 'store/auth/updater';

function Updaters() {
  return (
    <>
      <AuthUpdater />
    </>
  );
}

export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={`%s - ${DEFAULT_APP_TITLE}`}
        defaultTitle={DEFAULT_APP_TITLE}
        htmlAttributes={{ lang: i18n.language }}
      ></Helmet>

      <Updaters />
      <NavBar />
      <Routes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <GlobalStyle />
    </BrowserRouter>
  );
}
