import { red } from '@mui/material/colors';

export const overrides = {
  palette: {
    grey: {
      300: '#d6d6d6',
    },
    primary: {
      main: 'rgb(25, 118, 210)',
      light: 'rgb(192, 255, 254)',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f5f7f9',
      paper: 'white',
    },
    text: {
      primary: '#57606a',
      secondary: '#1e2532',
    },
  },
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 34,

          '&.Mui-expanded': {
            minHeight: 'auto',
          },
        },
        content: {
          margin: '4px 0',

          '&.Mui-expanded': {
            margin: '10px 0',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          marginBottom: 5,

          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '0.9rem',
        },
        h4: {
          fontWeight: 300,
          fontSize: '1.8rem',
        },
        subtitle1: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#1e2532',
        },
        subtitle2: {
          fontSize: '0.8rem',
          color: 'rgb(130, 139, 162)',
          fontWeight: 400,
        },
        caption: {
          fontSize: '0.85rem',
          color: '#838e9a',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          minWidth: 200,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '4px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '0.8em',
          height: '0.8em',
        },
      },
    },
  },
};
