import { Section, SectionTitle, SectionBody } from '../style';
import { capitalize } from 'utils/capitalize';
import { User } from 'store/auth/types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

type Props = {
  user: User;
  onPlanChange: () => void;
};

export function PlanSection({ user, onPlanChange }: Props) {
  return (
    <Section>
      <SectionTitle variant="caption">Your plan</SectionTitle>
      <SectionBody>
        <Grid alignItems="center" container>
          <Grid item sm={6}>
            <Typography variant="subtitle1">
              <strong>{capitalize(user.type)}</strong>
            </Typography>
            <Typography variant="body1">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Consectetur cupiditate earum illo dolores iure cumque veniam totam
              rem est.
            </Typography>
          </Grid>

          <Grid mt={2} textAlign="center" item sm={6}>
            <Button onClick={onPlanChange} variant="outlined" color="primary">
              Change plan
            </Button>
          </Grid>
        </Grid>
      </SectionBody>
    </Section>
  );
}
