import {
  googleAuthProvider,
  facebookAuthProvider,
  githubAuthProvider,
} from 'app/services/firebase';
import { AuthOptions } from 'types/Auth';
import { User, NewUserProps, AccountType } from 'store/auth/types';
import pick from 'lodash.pick';
import { AuthProvider, User as FirebaseUser } from 'firebase/auth';

/**
 * Util function that returns auth provider based on AuthOption param
 * @param option AuthOptions
 * @returns AuthProvider
 */
export const getProvider = (option: AuthOptions): AuthProvider => {
  const authMap = {
    [AuthOptions.GOOGLE]: googleAuthProvider,
    [AuthOptions.FACEBOOK]: facebookAuthProvider,
    [AuthOptions.GITHUB]: githubAuthProvider,
  };

  const provider = authMap[option];

  if (!provider) {
    throw Error('Provider option is not defined');
  }

  return provider;
};

/**
 * Util function that takes FirebaseUser data returned from auth and
 * modifies in data that we want to store in app store
 * @param data FirebaseUser | null
 * @returns User | null
 */
export const pickUserProps = (data: FirebaseUser | null): User | null => {
  if (!data) {
    return null;
  }

  const authProps = pick(data, [
    'uid',
    'email',
    'emailVerified',
    'phoneNumber',
  ]);

  const newProps: NewUserProps = {
    type: AccountType.FREE,
    firstName: data.displayName || '',
    lastName: '',
  };

  const output = { ...authProps, ...newProps };

  return output;
};
