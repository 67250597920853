import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from 'store/auth/hooks';
import { PageWrapper } from '../PageWrapper';
import { LoadingIndicator } from '../LoadingIndicator';

export const Protected = ({ children }) => {
  const location = useLocation();
  const { user, isLoaded } = useAuth();

  if (!isLoaded) {
    return (
      <PageWrapper>
        <LoadingIndicator />
      </PageWrapper>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
