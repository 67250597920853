import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { auth, firestore } from 'app/services/firebase';
import { setDoc, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { User as FirebaseUser } from 'firebase/auth';
import {
  updateError,
  updateIsLoading,
  updateUser,
  updateIsLoaded,
} from './reducer';
import { pickUserProps } from 'utils/auth';
import { USERS_COL } from 'constants/database';

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.auth.user);
  const error = useAppSelector(state => state.auth.error);
  const isLoading = useAppSelector(state => state.auth.isLoading);
  const isLoaded = useAppSelector(state => state.auth.isLoaded);

  const setUser = useCallback(
    user => {
      dispatch(updateUser({ user }));
    },
    [dispatch],
  );

  const setError = useCallback(
    error => {
      dispatch(updateError({ error }));
    },
    [dispatch],
  );

  const setIsLoading = useCallback(
    isLoading => {
      dispatch(updateIsLoading({ isLoading }));
    },
    [dispatch],
  );

  const setIsLoaded = useCallback(
    isLoaded => {
      dispatch(updateIsLoaded({ isLoaded }));
    },
    [dispatch],
  );

  return {
    error,
    setError,
    isLoading,
    setIsLoading,
    isLoaded,
    setIsLoaded,
    user,
    setUser,
    auth,
  };
};

export const useGetOrCreateUser = () => {
  return useCallback(async (data: FirebaseUser | null) => {
    const userData = pickUserProps(data);

    if (!userData) {
      return null;
    }

    // create a user ref
    const userRef = doc(firestore, USERS_COL, userData.uid);

    // get user if it exists
    const userSnap = await getDoc(userRef);

    // return if exists
    if (userSnap.exists()) {
      return userSnap.data();
    }

    // create new user if it doesn't exist
    await setDoc(userRef, userData);

    return userData;
  }, []);
};

export const useUpdateUser = () => {
  return useCallback(async data => {
    const userRef = doc(firestore, USERS_COL, data.uid);

    return updateDoc(userRef, data);
  }, []);
};

export const useDeleteUser = () => {
  return useCallback(async (uid: string) => {
    const userRef = doc(firestore, USERS_COL, uid);

    return deleteDoc(userRef);
  }, []);
};
