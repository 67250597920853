import { useState } from 'react';
import { LoginButton } from './styled';
import { LoginModal } from '../LoginModal';

export const LoggedOutNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LoginButton onClick={handleOpen}>Login</LoginButton>
      <LoginModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};
