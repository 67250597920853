import { API_URL } from 'constants/application';

export const calculate = async data => {
  const body = JSON.stringify(data);
  const headers = {
    'Content-Type': 'application/json',
  };

  const payload = {
    method: 'POST',
    headers,
    body,
  };

  const response = await fetch(API_URL, payload);
  const json = await response.json();

  // Handle error if status is 0
  if (json.status === 0) {
    let error;

    if (json.errors.length && json.errors[0].e) {
      error = json.errors[0].e;
    } else {
      error = 'Error in calculation!';
    }

    throw Error(error);
  }

  return json;
};
