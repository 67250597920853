import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageWrapper } from 'app/components/PageWrapper';
import { useAuth } from 'store/auth/hooks';
import { PlanSection } from './sections/PlanSection';
import { AccountSection } from './sections/AccountSection';
import { DeleteAccountSection } from './sections/DeleteAccountSection';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export function ProfilePage() {
  const { user, setUser } = useAuth();

  const onPlanChange = useCallback(() => {
    console.log('TODO: implement plan change');
  }, []);

  if (!user) {
    return null;
  }

  return (
    <PageWrapper maxWidth={false} bg="true">
      <Helmet>
        <title>Profile Page</title>
      </Helmet>

      <Container maxWidth="md">
        <Typography variant="h4">Profile settings</Typography>
        <PlanSection onPlanChange={onPlanChange} user={user} />
        <AccountSection setUser={setUser} user={user} />
        <DeleteAccountSection />
      </Container>
    </PageWrapper>
  );
}
