import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';

export const Section = styled('div')`
  margin: 1rem 0 2rem;
`;

export const SectionTitle = styled(Typography)`
  margin-bottom: 0.3rem;
  margin-left: 0.6rem;
  display: block;
`;

export const SectionBody = styled('div')`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 5px;
  padding: 1.6rem;
`;

export const CustomInput = styled(InputBase)`
  label + & {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  & .MuiInputBase-input {
    background-color: ${({ theme }) => theme.palette.background.default};
    position: relative;
    padding: 10px 12px;
    border-radius: 4px;
  }
`;
