import { useCallback } from 'react';
import {
  setIsCalculatingAction,
  setOutputAction,
  setSelected,
  setError,
  clear,
} from './reducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { calculate } from 'app/api';
import { pickOutputProps } from 'utils/output';
import { useParsedBins } from 'store/bins/hooks';
import { useParsedItems } from 'store/items/hooks';
import { toast } from 'react-toastify';

export function useOutput() {
  return useAppSelector(state => state.output);
}

export const useSetIsCalculating = function () {
  const dispatch = useAppDispatch();

  return useCallback(
    (isCalculating: boolean) => {
      dispatch(setIsCalculatingAction({ isCalculating }));
    },
    [dispatch],
  );
};

export const useSetOutput = function () {
  const dispatch = useAppDispatch();

  return useCallback(
    output => {
      dispatch(setOutputAction(output));
    },
    [dispatch],
  );
};

export const useSetError = function () {
  const dispatch = useAppDispatch();

  return useCallback(
    error => {
      dispatch(setError(error));
    },
    [dispatch],
  );
};

export const useSetSelected = function () {
  const dispatch = useAppDispatch();

  return useCallback(
    row => {
      dispatch(setSelected(row));
    },
    [dispatch],
  );
};

export const useCalculate = function () {
  const setIsCalculating = useSetIsCalculating();
  const setOutput = useSetOutput();
  const setError = useSetError();

  const items = useParsedItems();
  const bins = useParsedBins();

  return useCallback(async () => {
    setIsCalculating(true);
    setError(null);

    let error: string | null = null;

    if (!items.length) {
      error = 'Please add some items!';
    }

    if (!bins.length) {
      error = 'Please add some bins!';
    }

    if (!bins.length && !items.length) {
      error = 'Please add some items and bins!';
    }

    if (error) {
      setError(error);
      setIsCalculating(false);
      return;
    }

    try {
      const data = await calculate({ items, bins });
      const output = pickOutputProps(data);

      setOutput(output);
    } catch (err) {
      console.log('[useCalculate]::Error', err);
      setError(err);
      toast.error((err as Error).message);
    } finally {
      setIsCalculating(false);
    }
  }, [bins, items, setIsCalculating, setOutput, setError]);
};

export function useClearOutput() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(clear());
  }, [dispatch]);
}
