import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MuiAvatar from '@mui/material/Avatar';
import { Button } from '../Button';

export const ProfileButton = styled(IconButton)`
  :hover {
    box-shadow: none;
    background-color: transparent;
  }
`;

export const AppBar = styled(MuiAppBar)`
  background-color: white;
  box-shadow: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Logo = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const LoginButton = styled(Button)`
  text-transform: initial;
`;

export const AuthOptionWrapper = styled('div')`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

export const Avatar = styled(MuiAvatar)`
  width: 30px;
  height: 30px;
  font-size: 14px;
`;
