import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/reducer';
import itemsReducer from './items/reducer';
import binsReducer from './bins/reducer';
import outputReducer from './output/reducer';

export const persistConfig = {
  key: 'root',
  blacklist: ['auth'],
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  items: itemsReducer,
  bins: binsReducer,
  output: outputReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export function configureAppStore() {
  const middlewares = [];

  const store = configureStore({
    reducer: persistedReducer,
    middleware: defaultMiddleware => [
      ...defaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions,
        },
      }),
      ...middlewares,
    ],
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
  });

  return store;
}

const store = configureAppStore();

export default store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
