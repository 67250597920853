import { initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG } from 'constants/firebase';
import {
  getAuth,
  connectAuthEmulator,
  FacebookAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { isDev, isEnabledEmulators } from 'utils/env';
import { seedDatabase } from 'app/services/firebase/seeds';

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export const googleAuthProvider = new GoogleAuthProvider();
export const facebookAuthProvider = new FacebookAuthProvider();
export const githubAuthProvider = new GithubAuthProvider();

if (isDev && isEnabledEmulators) {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  seedDatabase();
}
