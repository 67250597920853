import { useEffect } from 'react';
import { auth } from 'app/services/firebase';
import { useGetOrCreateUser, useAuth } from './hooks';

export default function AuthUpdater(): null {
  const { setUser, setIsLoaded } = useAuth();
  const getOrCreateUser = useGetOrCreateUser();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async authData => {
      let user;

      try {
        user = await getOrCreateUser(authData);
      } catch (error) {
        console.log('[AuthUpdater]::Error on auth state change', error);
      }

      setUser(user || null);
      setIsLoaded(true);
    });

    return unsubscribe;
  }, [getOrCreateUser, setUser, setIsLoaded]);

  return null;
}
