import { User as FirebaseUser } from 'firebase/auth';

export enum AccountType {
  FREE = 'free',
  PREMIUM = 'premium',
}

// Props coming from firebase auth
export type AuthUserProps = Pick<
  FirebaseUser,
  'emailVerified' | 'phoneNumber' | 'uid' | 'email'
>;

// Props that we want to add and maintain in user collection
export type NewUserProps = {
  type: AccountType;
  firstName: string;
  lastName: string;
};

export type User = AuthUserProps & NewUserProps;

export interface AuthState {
  user: User | null;
  error: string | null;
  isLoading: boolean;
  isLoaded: boolean;
}
