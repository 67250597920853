import { createSlice } from '@reduxjs/toolkit';

export interface OutputItem {
  n: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface OutputBin {
  n: string;
  w: number;
  h: number;
  o: number;
  wa: number;
  q: number;
  iq: number;
  items: OutputItem[];
}

export interface Output {
  totalQuantityBins: number;
  totalUsedArea: number;
  totalWastedArea: number;
  totalNumberItems: number;
  totalNumberItemsUsed: number;
  totalNumberItemsNotUsed: number;
  bins: OutputBin[];
}

type Selected = any;

export type OutputState = {
  isCalculating: boolean;
  selected: null | Selected;
  value: Output | null;
  error: null | string;
};

const initialState: OutputState = {
  isCalculating: false,
  selected: null,
  value: null,
  error: null,
};

const outputSlice = createSlice({
  name: 'output',
  initialState,
  reducers: {
    setOutputAction(state, { payload }) {
      state.value = payload;
    },
    setIsCalculatingAction(state, { payload: { isCalculating } }) {
      state.isCalculating = isCalculating;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    setSelected(state, { payload }) {
      state.selected = payload;
    },
    clear(state) {
      state.value = null;
    },
  },
});

export const {
  setOutputAction,
  setIsCalculatingAction,
  setError,
  setSelected,
  clear,
} = outputSlice.actions;
export default outputSlice.reducer;
