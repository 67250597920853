import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

export const PageWrapper = styled(Container)<{ bg?: string; center?: string }>`
  min-height: 100vh;
  padding-top: var(--navbar-height);
  width: 100%;
  display: flex;
  background-color: ${({ theme, bg }) =>
    bg ? theme.palette.background.default : theme.palette.background.paper};

  ${({ center }) =>
    center &&
    `
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;
