import { Output } from 'store/output/reducer';
import pick from 'lodash.pick';
import { uuidv4 } from '@firebase/util';

/**
 * Util function that takes data return from solver
 * and returns the data ready for a state
 * @param data FirebaseUser | null
 * @returns User | null
 */
export const pickOutputProps = (data: any): Output | null => {
  if (!data) {
    return null;
  }

  const output = pick(data, [
    'totalQuantityBins',
    'totalUsedArea',
    'totalWastedArea',
    'totalNumberItems',
    'totalNumberItemsUsed',
    'totalNumberItemsNotUsed',
    'bins',
  ]);

  // Add ids to items
  // TODO: this should be added on API side
  output.bins.forEach((bin, binId) => {
    bin.id = uuidv4();
    bin.items.forEach(item => {
      item.id = uuidv4();
      item.binId = bin.id;
    });
  });

  return output;
};
