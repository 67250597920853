import { createTheme } from '@mui/material/styles';
import { overrides } from './mui-overrides';

declare module '@mui/material/styles' {
  interface Theme {
    mediaWidth?: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    mediaWidth?: any;
  }
}

// TODO: Figure this out
// const mediaWidth = {
//   upToXs: ({ theme }) => `@media (max-width: ${theme.breakpoints.values.xs}px)`,
//   upToSm: ({ theme }) => `@media (max-width: ${theme.breakpoints.values.sm}px)`,
//   upToMd: ({ theme }) => `@media (max-width: ${theme.breakpoints.values.md}px)`,
//   upToLg: ({ theme }) => `@media (max-width: ${theme.breakpoints.values.lg}px)`,
//   upToXl: ({ theme }) => `@media (max-width: ${theme.breakpoints.values.xl}px)`,
//   downToXs: ({ theme }) =>
//     `@media (max-width: ${theme.breakpoints.values.xs}px)`,
//   downToSm: ({ theme }) =>
//     `@media (max-width: ${theme.breakpoints.values.sm}px)`,
//   downToMd: ({ theme }) =>
//     `@media (max-width: ${theme.breakpoints.values.md}px)`,
//   downToLg: ({ theme }) =>
//     `@media (max-width: ${theme.breakpoints.values.lg}px)`,
//   downToXl: ({ theme }) =>
//     `@media (max-width: ${theme.breakpoints.values.xl}px)`,
// };

// A custom theme for this app
export const theme = createTheme({
  ...overrides,
});
