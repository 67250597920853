import React from 'react';
import { StyledButton, LoadingSpinner } from './styled';
import { ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

export function Button({ children, isLoading, ...rest }: Props) {
  return (
    <StyledButton {...rest}>
      {children}
      {isLoading && <LoadingSpinner size="16px" />}
    </StyledButton>
  );
}
