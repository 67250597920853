import { Section, SectionTitle, SectionBody } from '../style';
import { DEFAULT_APP_TITLE } from 'constants/application';
import { useState, useCallback } from 'react';
import { Modal } from 'app/components/Modal';
import { useAuth, useDeleteUser } from 'store/auth/hooks';
import { Button } from 'app/components/Button';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

enum Messages {
  ERROR = 'Something went wrong, please try again later!',
  SUCCESS = 'User data succesfully updated!',
  LOG_ERROR = '[handleDelete]::Error delting user',
  LOG_SUCCESS = '[handleDelete]::Success deleting user',
}

type ModalProps = {
  onDeleteAccount: () => void;
  handleClose: () => void;
  isLoading: boolean;
  isOpen: boolean;
};

const ConfirmModal = ({
  onDeleteAccount,
  handleClose,
  isOpen,
  isLoading,
}: ModalProps) => {
  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <Typography mb={2} variant="h4">
        Please confirm
      </Typography>
      <Typography mb={2} variant="body1">
        Are you sure you want to delete you account? <br></br>
        Once you do this all your information will be lost.
      </Typography>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onDeleteAccount}
        variant="contained"
        color="error"
      >
        <span>Confirm</span>
      </Button>
    </Modal>
  );
};

export function DeleteAccountSection() {
  const { user, auth } = useAuth();

  const deleteUser = useDeleteUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(() => {
    setIsLoading(true);

    if (!user) {
      return;
    }

    deleteUser(user.uid)
      .then(() => {
        console.log(Messages.LOG_SUCCESS);
        auth.signOut();
      })
      .catch(error => {
        console.log(Messages.LOG_ERROR, error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [deleteUser, user, auth]);

  return (
    <Section>
      <SectionTitle variant="caption">Delete account</SectionTitle>
      <SectionBody>
        <Grid container>
          <Grid item sm={8}>
            <Typography variant="body1">
              Please only delete your account if you don't want to use{' '}
              <strong>{DEFAULT_APP_TITLE}</strong> anymore. This action is
              permanent and all your account data will be lost.
            </Typography>
          </Grid>

          <Grid mt={2} textAlign="center" item sm={4}>
            <Button
              onClick={() => setIsModalOpen(true)}
              color="error"
              variant="contained"
            >
              Delete account
            </Button>
          </Grid>
        </Grid>
      </SectionBody>

      <ConfirmModal
        isLoading={isLoading}
        onDeleteAccount={handleDelete}
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </Section>
  );
}
