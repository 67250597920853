import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from 'store/auth/hooks';
import { useState } from 'react';
import { ProfileButton, Avatar } from './styled';
import { Link } from 'react-router-dom';

export const LoggedInNav = () => {
  const { user, auth } = useAuth();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    auth.signOut();
    handleClose();
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Tooltip title="Open profile settings">
        <ProfileButton onClick={handleOpen}>
          <Avatar>{user.firstName?.[0]}</Avatar>
        </ProfileButton>
      </Tooltip>

      <Menu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleClose}
      >
        <MenuItem to="/profile" component={Link} onClick={handleClose}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
