import { createSlice } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';

export interface Item {
  name?: string;
  width?: string;
  height?: string;
  qty?: string;
  rotation?: boolean;
}

const createNewItem = ({
  name = '',
  width = '',
  height = '',
  qty = '',
  rotation = false,
} = {}): Item => ({
  name,
  width,
  height,
  qty,
  rotation,
});

const createInitialState = (n = 5) =>
  Array.from(Array(n), () => createNewItem());

const initialState: Item[] = createInitialState();

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    updateItem(state, { payload: { index, key, value } }) {
      state[index][key] = value;

      // If its last row, add new row
      if (index === current(state).length - 1) {
        state.push(createNewItem());
      }
    },
    removeItem(state, { payload: { index } }) {
      state.splice(index, 1);
    },
    addItem(state) {
      state.push(createNewItem());
    },
    duplicateItem(state, { payload: { item, index } }) {
      state.splice(index, 0, item);
    },
    clearItems() {
      return createInitialState();
    },
    setItems(_, { payload }) {
      return payload;
    },
  },
});

export const {
  updateItem,
  removeItem,
  addItem,
  duplicateItem,
  clearItems,
  setItems,
} = itemsSlice.actions;
export default itemsSlice.reducer;
