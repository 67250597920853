import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';

const initialState: AuthState = {
  user: null,
  error: null,
  isLoading: false,
  isLoaded: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser(state, { payload: { user } }) {
      state.user = user;
    },
    updateError(state, { payload: { error } }) {
      state.error = error;
    },
    updateIsLoading(state, { payload: { isLoading } }) {
      state.isLoading = isLoading;
    },
    updateIsLoaded(state, { payload: { isLoaded } }) {
      state.isLoaded = isLoaded;
    },
  },
});

export const { updateUser, updateError, updateIsLoading, updateIsLoaded } =
  authSlice.actions;
export default authSlice.reducer;
