import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)`
  width: 350px;
  min-height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2)};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
