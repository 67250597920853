import { styled } from '@mui/material';
import { Button } from '../Button';

export const Wrapper = styled(Button)`
  margin-right: 10px;
  text-transform: initial;

  > span {
    margin-left: 5px;
  }
`;
