import { Wrapper } from './styled';
import { useCallback } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useClearBins } from 'store/bins/hooks';
import { useClearItems } from 'store/items/hooks';
import { useClearOutput } from 'store/output/hooks';
import { useOutput } from 'store/output/hooks';

export const Clear = () => {
  const { value } = useOutput();

  const clearItems = useClearItems();
  const clearBins = useClearBins();
  const clearOutput = useClearOutput();

  const clear = useCallback(() => {
    clearItems();
    clearBins();
    clearOutput();
  }, [clearItems, clearBins, clearOutput]);

  if (!value) {
    return null;
  }

  return (
    <Wrapper onClick={clear} variant="outlined" color="info" size="small">
      <DeleteOutlineIcon />
      <span>Clear</span>
    </Wrapper>
  );
};
