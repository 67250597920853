import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { useAuth } from 'store/auth/hooks';
import { Calculate } from '../Calculate';
import { Clear } from '../Clear';
import { LoggedInNav } from './LoggedInNav';
import { LoggedOutNav } from './LoggedOutNav';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthOptionWrapper } from './styled';

export function Nav() {
  const { user, isLoading } = useAuth();

  const authOptions = useMemo(() => {
    if (isLoading) return <CircularProgress size={20} />;
    else if (user) return <LoggedInNav />;
    else return <LoggedOutNav />;
  }, [user, isLoading]);

  return (
    <Box display={'flex'} alignItems="center" ml="auto">
      <Clear />
      <Calculate />
      <AuthOptionWrapper>{authOptions}</AuthOptionWrapper>
    </Box>
  );
}
