import { styled } from '@mui/material';

export const SocialButtons = styled('div')`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled('div')`
  max-width: 400px;
  margin-top: 1rem;
`;

export const AuthPanel = styled('div')`
  text-align: center;
  padding: 1rem;
`;
