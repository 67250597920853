import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useCallback, useMemo } from 'react';

import {
  updateItem,
  removeItem,
  addItem,
  duplicateItem,
  clearItems,
  setItems,
} from './reducer';
import { json2csv } from 'json-2-csv';
import { exportCSV, importCSV } from 'utils/csv';

const requiredBinsFields = ['height', 'width'];

export function useBins() {
  return useAppSelector(state => state.bins);
}

export function useParsedBins() {
  const bins = useBins();

  return useMemo(() => {
    const filtered = bins.filter(item => {
      return requiredBinsFields.every(field => item[field]);
    });

    const mapped = filtered.map(({ name, width, height }, i) => ({
      n: name || `Bin ${i}`,
      w: Number(width),
      h: Number(height),
      id: i,
    }));

    return mapped;
  }, [bins]);
}

export function useUpdateItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    (index, key, value) => {
      dispatch(updateItem({ index, key, value }));
    },
    [dispatch],
  );
}

export function useRemoveItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    index => {
      dispatch(removeItem({ index }));
    },
    [dispatch],
  );
}

export function useAddItem() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(addItem());
  }, [dispatch]);
}

export function useDuplicateItem() {
  const dispatch = useAppDispatch();

  return useCallback(
    (index, item) => {
      dispatch(duplicateItem({ index, item }));
    },
    [dispatch],
  );
}

export function useClearBins() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(clearItems());
  }, [dispatch]);
}

export function useSetBins() {
  const dispatch = useAppDispatch();

  return useCallback(
    bins => {
      dispatch(setItems(bins));
    },
    [dispatch],
  );
}

export function useExportBinsCSV() {
  const bins = useBins();

  return useCallback(
    async filename => {
      const data = await json2csv(bins);

      exportCSV(data, `${filename}.csv`);
    },
    [bins],
  );
}

export function useImportBinsCSV() {
  const setBins = useSetBins();

  return useCallback(
    event => {
      importCSV({
        onSuccess: res => {
          setBins(res);
        },
        event,
      });
    },
    [setBins],
  );
}
