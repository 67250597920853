import { Routes as RoutesWrapper, Route } from 'react-router-dom';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { Protected } from './components/Protected';
import { ProfilePage } from './pages/ProfilePage';

export function Routes() {
  return (
    <RoutesWrapper>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={
          <Protected>
            <ProfilePage />
          </Protected>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </RoutesWrapper>
  );
}
