import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import { Nav } from './Nav';
import { AppBar, Logo } from './styled';

export function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Logo to="/">
            <Typography variant="body1" component="span" sx={{ flexGrow: 1 }}>
              {process.env.REACT_APP_APPLICATION_NAME}
            </Typography>
          </Logo>

          <Nav />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
