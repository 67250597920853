import { Helmet } from 'react-helmet-async';
import { useAuth } from 'store/auth/hooks';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { AuthOptions } from 'types/Auth';
import { SocialButtons, ErrorWrapper } from './styled';
import {
  UserCredential,
  AuthErrorCodes,
  linkWithPopup,
  signInWithPopup,
} from 'firebase/auth';
import {
  GoogleLoginButton,
  FacebookLoginButton,
  GithubLoginButton,
} from 'react-social-login-buttons';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { FirebaseError } from 'firebase/app';
import { getProvider } from 'utils/auth';
import { AuthPanel } from './styled';
import { Modal } from 'app/components/Modal';

enum Messages {
  LOG_SUCCESS = '[signIn:handleSuccess]::Success signing in',
  LOG_ERROR = '[signIn:handleError]::Error signing in',
}

const errorMap = {
  [AuthErrorCodes.NEED_CONFIRMATION]:
    'Account with this email is already in use',
  default: 'Something went wrong, please try again a bit later.',
};

type ModalProps = {
  handleClose: () => void;
  isOpen: boolean;
};

export function LoginModal({ handleClose, isOpen }: ModalProps) {
  const { error, setError, setIsLoading, isLoading, auth } = useAuth();

  const handleSuccess = useCallback(async ({ user }: UserCredential) => {
    console.log(Messages.LOG_SUCCESS, user.uid);
  }, []);

  const handleError = useCallback(
    async (error: FirebaseError | Error) => {
      console.log(Messages.LOG_ERROR, error);

      if (error instanceof FirebaseError) {
        setError(error.code);
      }

      setIsLoading(false);
    },
    [setError, setIsLoading],
  );

  const signInOrLink = useCallback(
    (type: AuthOptions) => {
      const provider = getProvider(type);

      setIsLoading(true);
      setError(null);

      const signIn = () => {
        return auth.currentUser
          ? linkWithPopup(auth.currentUser, provider)
          : signInWithPopup(auth, provider);
      };

      signIn()
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleError, handleSuccess, setIsLoading, setError, auth],
  );

  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <Helmet>
        <title>Login page</title>
      </Helmet>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AuthPanel>
          <Typography variant="h4">Please login</Typography>

          <SocialButtons>
            <GoogleLoginButton
              onClick={() => signInOrLink(AuthOptions.GOOGLE)}
            />
            <FacebookLoginButton
              onClick={() => signInOrLink(AuthOptions.FACEBOOK)}
            />
            <GithubLoginButton
              onClick={() => signInOrLink(AuthOptions.GITHUB)}
            />
          </SocialButtons>

          {error && (
            <ErrorWrapper>
              <Typography color="error" variant="body1">
                {errorMap[error] || errorMap.default}
              </Typography>
            </ErrorWrapper>
          )}
        </AuthPanel>
      )}
    </Modal>
  );
}
