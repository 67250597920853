import * as React from 'react';
import MuiModal from '@mui/material/Modal';
import { Wrapper } from './styled';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
};

export function Modal({ isOpen, handleClose, children }: Props) {
  return (
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper>{children}</Wrapper>
    </MuiModal>
  );
}
