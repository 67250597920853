import { useState, useCallback } from 'react';
import { Section, SectionTitle, SectionBody, CustomInput } from '../style';
import { User } from 'store/auth/types';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { useUpdateUser } from 'store/auth/hooks';
import { Button } from 'app/components/Button';

type Props = {
  user: User;
  setUser: (user: any) => void;
};

enum Messages {
  ERROR = 'Something went wrong, please try again later!',
  SUCCESS = 'User data succesfully updated!',
  LOG_ERROR = '[handleSubmit]::Error updating user',
  LOG_SUCCESS = '[handleSubmit]::Success updating user',
}

export function AccountSection({ user, setUser }: Props) {
  const updateUser = useUpdateUser();

  const [state, updateState] = useState({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    phoneNumber: user.phoneNumber || '',
    email: user.email || '',
  });

  const [error, setError] = useState<null | string>(null);
  const [message, setMessage] = useState<null | string>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const handleChange = useCallback(event => {
    const { value, name } = event.target;

    updateState(state => ({ ...state, [name]: value }));
    setIsTouched(true);
    setMessage(null);
    setError(null);
  }, []);

  const handleSubmit = useCallback(
    event => {
      event?.preventDefault();

      // Exit if not touched
      if (!isTouched) {
        return;
      }

      const payload = { uid: user.uid, ...state };

      updateUser(payload)
        .then(() => {
          console.log(Messages.LOG_SUCCESS);
          setMessage(Messages.SUCCESS);
          setUser({ ...user, ...payload });
          setIsTouched(false);
        })
        .catch(error => {
          console.log(Messages.LOG_ERROR, error);
          setError(Messages.ERROR);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [state, isTouched, updateUser, setUser, user],
  );

  return (
    <form action="POST" onSubmit={handleSubmit}>
      <Section>
        <SectionTitle variant="caption">Account details</SectionTitle>
        <SectionBody>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="first-name">
                  First name
                </InputLabel>

                <CustomInput
                  fullWidth
                  name="firstName"
                  id="first-name"
                  placeholder="First name"
                  onChange={handleChange}
                  value={state.firstName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="last-name">
                  Last name
                </InputLabel>

                <CustomInput
                  fullWidth
                  name="lastName"
                  id="last-name"
                  placeholder="Last name"
                  onChange={handleChange}
                  value={state.lastName}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid mt={4} spacing={2} container>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="email-address">
                  Email
                </InputLabel>

                <CustomInput
                  fullWidth
                  name="email"
                  id="email-address"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={state.email}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="phone-number">
                  Phone number
                </InputLabel>

                <CustomInput
                  fullWidth
                  name="phoneNumber"
                  id="phone-number"
                  placeholder="Phone number"
                  onChange={handleChange}
                  value={state.phoneNumber}
                />
              </FormControl>
            </Grid>
          </Grid>

          {(error || message) && (
            <Grid mt={2} container>
              <Grid item sm={6}>
                <Typography variant="body1" color={error ? 'error' : 'green'}>
                  {error || message}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid mt={2} container textAlign="center">
            <Button
              disabled={isUpdating}
              isLoading={isUpdating}
              type="submit"
              variant="outlined"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </SectionBody>
      </Section>
    </form>
  );
}
